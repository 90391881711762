
export const i18n = {
    documentTitles: {
        rootPageName: "Студия дизайна INTERIOR MY DREAMS",
        mainTitle: "Студия дизайна Interior MY DREAMS | Уникальный интерьер и гибкие услуги дизайн проекта",
        mainDescription: "Стильные и функциональные интерьеры с гибкими тарифом, где вы сможете подобрать только те услуги, которые вам необходимы.  Разрабатываем дизайн проекты по всей России.",
        portfolioSettingName: "Настройки",
        calculatorPageName: "«Конструктор» – это уникальный настраиваемый пакет дизайнерских услуг, который позволяет создать идеальный проект",
        calculatorDescription: "Тариф «Конструктор» — это настраиваемый пакет дизайнерских услуг для интерьера, который позволяет создать проект, идеально отвечающий вашим потребностям и вписывающийся в бюджет. Вы можете выбрать нужные опции чтобы получить именно тот интерьер, о котором всегда мечтали.",
        contactTitles: "Наши контакты | Студия дизайна INTERIOR MY DREAMS",
        portfolioTitles: "Портфолио дизайн-студии Interior My Dreams:стильные и функциональные интерьеры вашей мечты.",
        portfolioDescription: "Interior My Dreams — дизайн-студия, специализирующаяся на создании уникальных интерьеров. Мы предлагаем широкий спектр услуг: разработку планировки, создание реалистичной 3d визуализации и коллажей, подбор материалов и мебели. Наши клиенты получают стильные и функциональные пространства, полностью соответствующие их требованиям и пожеланиям."
    },
    company: {
        name: 'INTERIOR MY DREAMS',
        phone: '+7 978 664 95 22',
        host: 'interiormydreams.ru',
        whatsappHref: 'https://wa.me/79786649522?text=Добрый день!',
        worckTime: "Ежедневно с 9 до 18"
    },
    menu: {
        rootPageName: "ГЛАВНАЯ",
        portfolio: "ПОРТФОЛИО",
        tariff: "УСЛУГИ",
        contacts: "КОНТАКТЫ",
        newsList: "НОВОСТИ",
    },
    contacts: {
        title: "НАШИ КОНТАКТЫ",
        contactBtn: "Связатся с нами"
    },
    footer: {
        title: 'Рассчитать стоимость проекта',
        info: 'Отправьте заявку и мы поможем вам рассчитать стоимость проекта или подобрать тариф',
        form: 'Имя',
        message: 'Сообщение',
        calculate: "Рассчитать проект",
        requestSended: "Заявка принята, в ближайшее времы мы свяжемся с Вами"
    },
    error: {
        nameRequired: "Необходимо указать имя",
        phoneError: "Укажите номер телефона в формате +7 (999) 999-99-99"
    },
    mainPage: {
        firstBlock: {
            title: "ДИЗАЙН ИНТЕРЬЕРА",
            info: "ИНДИВИДУАЛЬНЫЙ ДИЗАЙН С ГИБКИМ ПАКЕТОМ УСЛУГ",
            info1: "ИНДИВИДУАЛЬНЫЙ ДИЗАЙН",
            info2: "С ГИБКИМ ПАКЕТОМ УСЛУГ",
            workBlock: {
                developProject: "Разрабатываем онлайн-проекты по всей России и СНГ",
                createProject: "Создаем стильные и реализуемые проекты",
                selectMaterials: "Подбираем реальные материалы под ваш бюджет",
                term: "Соблюдаем четкие сроки, подкрепленных договором."
            }
        },
        portfolioBlock: {
            title: "ПОРТФОЛИО",
            button: 'Смотреть все проекты'
        },
        tariffBlock: {
            title: 'УСЛУГИ',
            commonTariff: 'Выберите тариф, который подходит вам',
            constructor: 'Соберите свой тариф',
            buttonOrder: 'Собрать свой пакет услуг',
            buttonTest: 'Пройти',
            constructorTitle: "Конструктор",
            constructorPrice: "от 500₽/м²",
            constructorOption: ["Планировочное решение", "Визуализация помещений", "Рабочая документация", "Авторский надзор"],
            constructorInfo: 'гибкий пакет дизайнерских услуг, который позволяет создать дизайн-проект, идеально подходящий под ваши пожелания и бюджет. Вы можете выбрать нужные опции чтобы получить именно тот интерьер, о котором всегда мечтали.'
        },
        lifeSickle: {
            title: "ЭТАПЫ РАБОТЫ",
            info: {
                first: {
                    title: 'Первая встреча',
                    info: 'Оставляйте заявку или звоните нам, мы расскажем Вам о деталях работы над проектом по телефону, а так же договоримся о встрече в удобное для Вас время в нашей студии или на объекте. На первой встрече или по телефону мы с Вами обсуждаем все Ваши требования к будущему интерьеру. Осматриваем и консультируем по первичной подготовке объекта к ремонту.'
                },
                second: {
                    title: 'Договор',
                    info: 'Мы с Вами заключаем официальный подробный надежный договор. В договоре описаны все нюансы будущей работы, такие как состав работ, сроки проведения работ и стоимость дизайна-проекта.'
                },
                third: {
                    title: 'Техническое задание',
                    info: 'На этом этапе мы составляем с Вами подробное техническое задание по нашему будущему проекту. Ознакамливаемся с материалами и определяемся со стилистикой. Прорабатываем варианты планировок.'
                },
                fourth: {
                    title: '3D визуализация интерьера',
                    info: 'Она поможет увидеть наглядно будущий интерьер как на фотографии. Мы делаем высококачественные визуализации с реальными материалами, мебелью, светильниками и т.д.'
                },
                five: {
                    title: 'Комплект чертежей',
                    info: 'Чертежи необходимы для полноценной и правильной реализации дизайна. В состав проекта входит подробная ведомость отделочных материалов с артикулами и указанием, где их можно приобрести.'
                },
                six: {
                    title: 'Онлайн-сопровождение проекта',
                    info: 'В рамках авторского надзора дизайнер регулярно приезжает на объект, контролирует ход проведения строительных работ и оперативно решает возникающие у строителей вопросы.'
                }
            }
        }
    },
    portfolio: {
        changeOrderLabel: "Изменить порядок",
        addProjectLabel: "Добавить новый проект",
        setting: "Настройки",
        categorySetting: "Настройки категорий",
        singleImageSetting: "Изображения без проекта",
        showMoreLabel: "Узнать больше",
        description: 'Описание',
        plan: 'План',
        gallery: 'Визуализация'
    },
    calculator: {
        title: "КОНСТРУКТОР",
        testTitle: "ПОДБОР ТАРИФА",
        titleHint: "СОБЕРИ СВОЙ ТАРИФ",
        calculatorSettingPageLink: "Настройка калькулятора",
        testSettingPageLink: "Настройка теста",
    },
    button: {
        calculate: "Узнать подробнее",
        edit: "Редактировать",
        tariffSettingLink: 'Настройка'
    }
};